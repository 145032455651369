import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TelegramShareButton,
  LinkedinShareButton,
} from 'next-share';
import Image from 'next/image';

const SocialShareModal = ({
  isOpen,
  setIsOpen,
  url,
  name,
  description,
  modalTitle = 'Share Your Response',
}) => {
  const notify = () =>
    toast.success('Link Copied', {
      className: 'toast-message-success',
    });
  let title = '';
  if (name) title = title + name;
  if (name && description) title += ' | ';
  if (description) title = title + description;

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all py-8 px-8 sm:my-8 w-full max-w-lg flex flex-col space-y-6">
          <div className="bg-white">
            <div className="text-center sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                <div className="pb-3 flex flex-row items-center justify-between">
                  <h2>{modalTitle}</h2>

                  <Image
                    src="/assets/icons/ExitCircle.svg"
                    onClick={() => setIsOpen(false)}
                    className="cursor-pointer"
                    height={25}
                    width={25}
                    alt="Close Modal"
                  />
                </div>
              </Dialog.Title>
              <div className="mt-2 flex flex-col border border-[#7D8398]/30 rounded-lg">
                <div className="flex h-12 flex-row px-2 rounded-md">
                  <input
                    type="text"
                    value={url}
                    placeholder="URL Link"
                    className="text-[#7D8398] px-2 w-full border-none bg-white focus:ring-0"
                  ></input>
                  <button
                    className="cursor-pointer shadow-inner hover:bg-[#489CF0] bg-[#2483E2] my-auto rounded-lg w-1/5 text-center text-sm text-white px-3.5 py-2 truncate"
                    onClick={() => {
                      copy(url);
                      notify();
                    }}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Social Media website icons list */}
          <div className="flex flex-row space-x-4 items-center justify-center">
            <FacebookShareButton url={url} quote={title} hashtag={'#rubber'}>
              <div className="flex flex-col items-center justify-center text-xs">
                <Image
                  priority
                  src="/assets/icons/FacebookIcon.svg"
                  height={0}
                  width={63}
                  className="self-center"
                  alt="Facebook Icon"
                />
              </div>
            </FacebookShareButton>

            <TelegramShareButton url={url} title={title}>
              <div className="flex flex-col items-center justify-center text-xs">
                <Image
                  priority
                  src="/assets/icons/TelegramIcon.svg"
                  height={0}
                  width={63}
                  className="self-center"
                  alt="Telegram Icon"
                />
              </div>
            </TelegramShareButton>

            <LinkedinShareButton url={url}>
              <div className="flex flex-col items-center justify-center text-xs">
                <Image
                  priority
                  src="/assets/icons/LinkedinIcon.svg"
                  height={0}
                  width={63}
                  className="self-center"
                  alt="Telegram Icon"
                />
              </div>
            </LinkedinShareButton>

            <TwitterShareButton url={url} title={title}>
              <div className="rounded-lg flex flex-col items-center justify-center text-xs">
                <Image
                  priority
                  src="/assets/icons/TwitterIcon.svg"
                  height={0}
                  width={63}
                  className="self-center"
                  alt="Twitter Icon"
                />
              </div>
            </TwitterShareButton>

            <WhatsappShareButton url={url} title={title} separator=":: ">
              <div className="flex flex-col items-center justify-center text-xs">
                <Image
                  priority
                  src="/assets/icons/WhatsappIcon.svg"
                  height={0}
                  width={63}
                  className="self-center"
                  alt="Whatsapp Icon"
                />
              </div>
            </WhatsappShareButton>

            <EmailShareButton url={url} subject={title} body="body">
              <div className="flex flex-col items-center justify-center text-xs">
                <Image
                  priority
                  src="/assets/icons/EmailIcon.svg"
                  height={0}
                  width={63}
                  className="self-center"
                  alt="Email Icon"
                />
              </div>
            </EmailShareButton>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default SocialShareModal;
