import React, { useState } from 'react';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import copy from 'copy-to-clipboard';
import SocialShareModal from './SocialShareModal';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CustomLink from './OutputComponents/CustomLink';
import axios from 'axios';
import { getUserId } from '@/utils/userIdUtil';
import { EVENT_IDS } from '@/constants/enums/events';
import { useRouter } from 'next/router';
import { forgeDecrypt } from '@/utils/cipherHelpers';
import { useAppUser } from '@/contexts/AppUserContext';
import { Leaf } from './editorOld/PromptComponents/OutputEditor';
// import {
//   CustomTableBodyCell,
//   tdWithCustomProp,
//   thWithCustomProp,
//   withCustomProp,
// } from './OutputComponents/CustomTableElements';
// import {
//   listItemWithCustomProp,
//   olWithCustomProp,
//   ulWithCustomProp,
// } from './OutputComponents/CustomListElements';

const RenderNode = ({ node, output }) => {
  if (!node) return;
  if (node?.type === 'text' || node?.text) {
    // return node.text;
    return (
      <Leaf attributes={node} leaf={node}>
        {node.text}
      </Leaf>
    );
  }

  const children = node?.children?.map((n, index) => (
    <RenderNode node={n} key={index} output={output} />
  ));

  switch (node.type) {
    case 'block-quote':
      return (
        <blockquote className="border-l-4 border-gray-500 pl-4 italic">
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return <ul className="list-disc list-inside">{children}</ul>;
    case 'heading-one':
      return (
        <h1 className="text-2xl font-bold" style={{ textAlign: node?.align }}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2
          className="text-xl font-semibold"
          style={{ textAlign: node?.align }}
        >
          {children}
        </h2>
      );
    case 'list-item':
      return <li>{children}</li>;
    case 'numbered-list':
      return <ol className="list-decimal list-inside">{children}</ol>;
    case 'mention':
      return (
        <span>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              a: CustomLink,
              // th: thWithCustomProp(outputTextStyles?.color || '#000000'),
              // td: tdWithCustomProp(outputTextStyles?.color || '#000000'),
              // ol: olWithCustomProp(outputTextStyles?.color || '#000000'),
              // ul: ulWithCustomProp(outputTextStyles?.color || '#000000'),
              // li: listItemWithCustomProp(outputTextStyles?.color || '#000000'),
            }}
          >
            {
              output.node_outputs?.find(
                (o) => o.named_output_variable === node.id
              )?.output_data
            }
          </ReactMarkdown>
        </span>
      );
    default:
      return <p style={{ textAlign: node?.align }}>{children}</p>;
  }
};

const OutputRenderer = ({
  output,
  url,
  customizedOutput,
  customizedOutputText,
  buttonStyles = {},
  outputTextStyles = {},
  isSharingEnabled = true,
}) => {
  const router = useRouter();
  const { auth0User: user } = useAppUser();
  const notify = () =>
    toast.success('Copied to Clipboard', {
      className: 'toast-message-success',
    });

  // let customizedOutputResponse =
  //   customizedOutput ||
  //   'This app does not show any output! Contact the app creator!';

  // console.log('customizedOutput', customizedOutput);
  // if (customizedOutput) {
  //   for (let i = 0; i < output.node_outputs.length; i++) {
  //     const stepOutputHolder = output.node_outputs[i];
  //     customizedOutputResponse = customizedOutputResponse.replaceAll(
  //       // eslint-disable-next-line no-useless-escape
  //       `\$\{required_variables.${stepOutputHolder.named_output_variable}\}`,
  //       stepOutputHolder.output_data
  //     );
  //   }
  // }

  const [showSocialModal, setShowSocialModal] = useState(false);

  const copyOutput = () => {
    const outputText = output?.node_outputs?.map((output) =>
      parse(output?.output_data?.trim())
    );
    copy(outputText?.join('\n\n'));
  };

  const saveCopyEvent = async () => {
    axios.post('/api/supabase/captureEvent', {
      user_id: await getUserId(user?.email),
      event_type: EVENT_IDS.COPY_OUTPUT,
      route: `/apps/${router.query?.appId}`,
      app_id: forgeDecrypt(router.query?.appId),
      metadata: {},
    });
  };

  const saveShareEvent = async () => {
    axios.post('/api/supabase/captureEvent', {
      user_id: await getUserId(user?.email),
      event_type: EVENT_IDS.SHARE_OUTPUT,
      route: `/apps/${router.query?.appId}`,
      app_id: forgeDecrypt(router.query?.appId),
      metadata: {},
    });
  };

  return (
    <div className="flex flex-col overflow-hidden py-3 border-t-2">
      <br />

      {customizedOutputText ? (
        <div
          className="px-4 py-4 break-words prose w-full custom-prose"
          style={{ color: outputTextStyles?.color || '#000000' }}
        >
          {JSON.parse(customizedOutput).map((node, index) => (
            <RenderNode node={node} key={index} output={output} />
          ))}

          {/* <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              a: CustomLink,
              // th: thWithCustomProp(outputTextStyles?.color || '#000000'),
              // td: tdWithCustomProp(outputTextStyles?.color || '#000000'),
              // ol: olWithCustomProp(outputTextStyles?.color || '#000000'),
              // ul: ulWithCustomProp(outputTextStyles?.color || '#000000'),
              // li: listItemWithCustomProp(outputTextStyles?.color || '#000000'),
            }}
          >            
            {customizedOutputResponse}
          </ReactMarkdown> */}
        </div>
      ) : (
        <div
          className="px-4 py-4 break-words prose w-full custom-prose"
          style={{ color: outputTextStyles?.color || '#000000' }}
        >
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              a: CustomLink,
              //   th: thWithCustomProp(outputTextStyles?.color || '#000000'),
              //   td: tdWithCustomProp(outputTextStyles?.color || '#000000'),
              //   ol: olWithCustomProp(outputTextStyles?.color || '#000000'),
              //   ul: ulWithCustomProp(outputTextStyles?.color || '#000000'),
              //   li: listItemWithCustomProp(outputTextStyles?.color || '#000000'),
            }}
          >
            {output?.node_outputs
              ?.map((output) => output?.output_data)
              .join('\n\n')}
          </ReactMarkdown>
        </div>
      )}

      <div className="flex flex-row gap-x-4 pt-4 pb-6 items-start justify-start px-4">
        <div
          onClick={() => {
            // copy(parse(output?.trim()))
            copyOutput();
            saveCopyEvent();
            notify();
          }}
          className="bg-[#F5F6F7] font-semibold text-sm px-4 py-2.5 text-[#181818] rounded-lg flex flex-row items-center cursor-pointer"
        >
          Copy Response
        </div>

        {isSharingEnabled && (
          <div
            onClick={() => {
              setShowSocialModal(true);
              saveShareEvent();
            }}
            className="bg-[#2483E2] font-semibold text-sm px-4 py-2.5 text-white rounded-lg flex flex-row items-center cursor-pointer"
            style={{
              backgroundColor: buttonStyles?.backgroundColor,
              borderColor: buttonStyles?.borderColor,
              // borderRadius: buttonStyles?.borderRadius,
              // borderWidth: buttonStyles?.borderWidth,
              color: buttonStyles?.color,
              // fontSize: buttonStyles?.fontSize,
              // fontStyle: buttonStyles?.fontStyle,
            }}
          >
            Share Response
            {/* <Image
            priority
            src="/assets/icons/ShareResponse.svg"
            height={0}
            width={16}
            className="self-center ml-2"
            alt=""
          /> */}
          </div>
        )}
      </div>

      {/*<div className="flex flex-col grow">
          <div className="text-black">
            <h2 className="text-md font-semibold underline-offset-2 pt-4 pb-3">
              Your AI-Generated Response
            </h2>
          </div>
  </div>*/}

      <SocialShareModal
        isOpen={showSocialModal}
        setIsOpen={setShowSocialModal}
        url={url}
        name=""
        description=""
        modalTitle="Share Your Output"
      />
    </div>
  );
};

export default OutputRenderer;
