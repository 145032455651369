import React from 'react';

export const Text = ({ label, styles }) => {
  return (
    <div className="leading-normal font-semibold flex flex-col text-left h-auto w-full my-3 break-words bg-transparent">
      <p style={styles} className="w-full">
        {label}
      </p>
    </div>
  );
  // return (
  //   <p className="w-fit font-medium text-[#000000] text-sm" style={styles}>
  //     {label}
  //   </p>
  // )
};
