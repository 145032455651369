import React, { useEffect, useState } from 'react';
import { useField, ErrorMessage } from 'formik';

export const TextAreaWithLabel = (props) => {
  const [field] = useField(props);

  return (
    <div className="flex flex-col w-full justify-center my-3 py-4">
      <div className="flex flex-row w-full items-center">
        <label
          htmlFor={props.name}
          className="w-fit font-medium text-md"
          style={{ color: props?.labelColor || '#121212' }}
        >
          {props.label}
        </label>
        {props?.required && (
          <p className="text-red-500 text-sm ml-1 items-start">*</p>
        )}
      </div>
      {props.description && (
        <div
          className="py-1 text-sm"
          style={{ color: props?.descriptionColor || '#9B9B9B' }}
        >
          {props.description}
        </div>
      )}

      <textarea
        {...field}
        id={props.name}
        rows={props.rows || '4'}
        className="mt-2 mb-2 w-full p-2.5 text-gray-900 bg-white rounded-lg border-2 text-sm border-[#E9E9E8] focus:ring-blue-500 focus:border-blue-500 hover:ring-[#7D8398] hover:border-[#7D8398]"
        placeholder={props.placeholder}
        required={props?.required || false}
      />
    </div>
  );
};
