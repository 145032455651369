import { ErrorMessage, useField } from 'formik';
import React from 'react';

export const SelectInput = ({ label, options, ...props }) => {
  const [field] = useField(props);

  return (
    <>
      <div className="flex flex-col md:w-1/5 justify-center my-4">
        <div className="flex flex-row space-x-4 items-center">
          <label
            htmlFor={props.id}
            className="w-fit font-medium text-[#000000]"
          >
            {label}
            {/* <label className="text-red-500">*</label> */}
          </label>

          <ErrorMessage
            name={props.name}
            component="span"
            className="error text-red-700 text-xs"
          />
        </div>
        <select
          {...field}
          {...props}
          className={`mt-2 mb-2 w-full p-2.5 text-xs text-gray-900 bg-white rounded-md border border-[#E9E9E8] focus:ring-blue-500 focus:border-blue-500`}
        >
          <option value="">Select a value</option>
          {options?.map(({ desc, value }) => (
            <option value={value} key={value}>
              {desc}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};
