import React from 'react';
import { useField, ErrorMessage } from 'formik';

export const TextInput = (props) => {
  const [field] = useField(props);

  return (
    <div className="flex flex-col w-full justify-center my-4">
      <div className="flex flex-row w-full space-x-4 items-center">
        <label
          htmlFor={props.id}
          className="w-fit font-medium text-[#000000] text-md"
        >
          {props.label}
        </label>
        <ErrorMessage
          name={props.name}
          component="span"
          className="error text-red-700 text-xs"
        />
      </div>
      {props?.description && (
        <div className="py-1 text-xs text-[#9B9B9B]">{props?.description}</div>
      )}
      <input
        {...field}
        {...props}
        className="mt-2 mb-2 w-full p-2.5 text-xs text-gray-900 bg-white rounded-md border border-[#E9E9E8] focus:ring-blue-500 focus:border-blue-500"
        required={props?.required || false}
      />
    </div>
  );
};
