import React from 'react';

export const Paragraph = ({ label, styles }) => {
  return (
    <div className="leading-normal font-normal flex flex-col text-left h-auto w-full my-3 break-words bg-transparent">
      <p style={styles} className="w-full">
        {label}
      </p>
    </div>
  );
};
