export const EVENT_IDS = {
  SIGNUP: 'SIGNUP' /* Sign up */,
  LOGIN: 'LOGIN' /* Log in */,
  UPGRADE_TO_PRO: 'UPGRADE_TO_PRO' /* Upgrade to PRO */,
  UPGRADE_TO_BUSINESS: 'UPGRADE_TO_BUSINESS' /* Upgrade to BUSINESS */,
  APP_VISIT: 'APP_VISIT' /* App visit */,
  EMBED_APP_VISIT: 'EMBED_APP_VISIT' /* Embed App visit */,
  GENERATE_OUTPUT_CLICK: 'GENERATE_OUTPUT_CLICK' /* Generate output */,
  GENERATE_OUTPUT_ERROR: 'GENERATE_OUTPUT_ERROR' /* Generate output error */,
  GENERATE_OUTPUT_SUCCESS:
    'GENERATE_OUTPUT_SUCCESS' /* Generate output success */,
  SHARE_OUTPUT: 'SHARE_OUTPUT' /* Share output */,
  COPY_OUTPUT: 'COPY_OUTPUT' /* Copy output */,
};
