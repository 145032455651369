import { useField, ErrorMessage } from 'formik';
import { useState } from 'react';
import slugify from 'slugify';

export const RadioButtonGroup = (props) => {
  const [field] = useField(props);
  const [slug, setSlug] = useState(slugify(`${props?.label}`));
  return (
    <div className="w-full flex flex-col justify-center my-3 py-4">
      <div className="flex flex-row w-full items-center">
        <label
          className="focus:outline-none w-fit pb-1 font-medium text-md"
          style={{ color: props?.labelColor }}
        >
          {props.label}
        </label>

        {props?.required && (
          <p className="text-red-500 text-sm ml-1 items-start">*</p>
        )}
      </div>

      {props?.description && (
        <p
          className="focus:outline-none pb-2.5 text-sm"
          style={{ color: props?.descriptionColor }}
        >
          {props?.description}
        </p>
      )}

      <div
        className={`flex ${
          props?.vertical ? 'flex-col' : 'flex-row'
        }  w-full flex-wrap`}
      >
        {props?.options?.map((option, index) => (
          <div
            key={index}
            className="h-12 py-3 mr-4 pr-4 text-[16px] focus:ring-[#2483E2] focus:border-[#2483E2] space-x-2"
          >
            <input
              {...field}
              checked={option.value === field.value}
              type="radio"
              id={`${slug}-${index}`}
              name={slug}
              value={option.value}
              required={props?.required ? index === 0 : false}
            />
            <label
              htmlFor={`${slug}-${index}`}
              className="focus:outline-none pb-2.5 text-sm cursor-pointer"
              style={{ color: props?.optionsColor }}
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
