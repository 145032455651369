import React from 'react';

export const Button = ({ styles = {}, label }) => {
  return (
    <div className="flex items-start text-center w-full justify-start py-3 cursor-pointer">
      <button style={{ ...styles }} type="submit">
        {label}
      </button>
    </div>
  );
  // return <button style={styles}>{label}</button>
};
