import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isHotkey from 'is-hotkey';
import {
  Editable,
  withReact,
  useSlate,
  Slate,
  useSelected,
  useFocused,
} from 'slate-react';
import {
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
  Range,
  ReactEditor,
} from 'slate';
import { withHistory } from 'slate-history';
import {
  FaBold,
  FaCode,
  FaItalic,
  FaQuoteLeft,
  FaUnderline,
} from 'react-icons/fa';
import {
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdLooksOne,
  MdLooksTwo,
} from 'react-icons/md';
import {
  CgFormatCenter,
  CgFormatJustify,
  CgFormatLeft,
  CgFormatRight,
} from 'react-icons/cg';

// import React, {
//   useMemo,
//   useCallback,
//   useRef,
//   useEffect,
//   useState,
// } from 'react';
// import { Editor, Transforms, Range, createEditor, Text } from 'slate';
// import { withHistory } from 'slate-history';
// import {
//   Slate,
//   Editable,
//   ReactEditor,
//   withReact,
//   useSelected,
//   useFocused,
// } from 'slate-react';
import { Variable } from '../Variable';

import { Portal } from './components';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

// TODO: Update this block to work with the new editor
const processNode = (node, variablesList, outputVariablesList) => {
  if (Text.isText(node)) {
    return node.text;
  } else if (node.type === 'mention') {
    const selectedVariable =
      variablesList?.find((v) => v.id === node.variable.id) ||
      outputVariablesList?.find((v) => v.id === node.variable.id);

    if (selectedVariable?.category && selectedVariable?.name)
      return `\${${selectedVariable?.category}.${selectedVariable?.name}}`;
    return '';
  }

  const processedPrompt = node.children
    ?.map((n) => processNode(n, variablesList, outputVariablesList))
    .join(' ');

  return processedPrompt;
};

export const performPromptSerialization = (
  nodes,
  variablesList,
  outputVariablesList
) => {
  return nodes
    ?.map((node) => processNode(node, variablesList, outputVariablesList))
    .join(' ');
};

const OutputEditor = ({
  variablesList = [],
  outputVariablesList = [],
  promptText,
  setPromptText,
}) => {
  const ref = useRef();
  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const renderElement = useCallback(
    (props) => (
      <Element
        {...props}
        variablesList={variablesList}
        outputVariablesList={outputVariablesList}
      />
    ),
    [variablesList, outputVariablesList]
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  // const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const editor = useMemo(
    () => withMentions(withReact(withHistory(createEditor()))),
    []
  );

  const getfilteredVariables = () => {
    if (!search) return [...variablesList, ...outputVariablesList];

    const filteredVariableList = variablesList.filter((variable) => {
      return variable.label.toLowerCase().indexOf(search.toLowerCase()) >= 0;
    });
    const filteredOutputVariablesList = outputVariablesList.filter(
      (variable) => {
        return variable.label.toLowerCase().indexOf(search.toLowerCase()) >= 0;
      }
    );

    return [...filteredVariableList, ...filteredOutputVariablesList];
  };

  const vars = getfilteredVariables() || [
    ...variablesList,
    ...outputVariablesList,
  ];

  useEffect(() => {
    if (target && vars.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [vars.length, editor, index, search, target]);

  return (
    <Slate
      editor={editor}
      value={JSON.parse(promptText || '[]')}
      onChange={(value) => {
        const { selection, operations, children } = editor;

        if (selection && Range.isCollapsed(selection)) {
          const [start] = Range.edges(selection);
          const wordBefore = Editor.before(editor, start, { unit: 'word' });
          const before = wordBefore && Editor.before(editor, wordBefore);
          const beforeRange = before && Editor.range(editor, before, start);
          const beforeText = beforeRange && Editor.string(editor, beforeRange);
          const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
          const after = Editor.after(editor, start);
          const afterRange = Editor.range(editor, start, after);
          const afterText = Editor.string(editor, afterRange);
          const afterMatch = afterText.match(/^(\s|$)/);

          if (beforeMatch && afterMatch) {
            setTarget(beforeRange);
            setSearch(beforeMatch[1]);
            setIndex(0);
            return;
          }
        }

        setTarget(null);

        // Handling the text change
        const isAstChange = operations.some(
          (op) => 'set_selection' !== op.type
        );
        if (isAstChange) {
          const content = JSON.stringify(value);
          setPromptText(content);
        }
      }}
    >
      <div className="w-full h-fit flex flex-row bg-white py-2 px-4 space-x-4 border-y">
        <div className="flex flex-row space-x-4">
          <MarkButton format="bold" icon={<FaBold className="w-4 h-4" />} />
          <MarkButton format="italic" icon={<FaItalic className="w-4 h-4" />} />
          <MarkButton
            format="underline"
            icon={<FaUnderline className="w-4 h-4" />}
          />
          <MarkButton format="code" icon={<FaCode className="w-4 h-4" />} />
        </div>

        <div className="flex flex-row space-x-4">
          <BlockButton
            format="heading-one"
            icon={<MdLooksOne className="w-4 h-4" />}
          />
          <BlockButton
            format="heading-two"
            icon={<MdLooksTwo className="w-4 h-4" />}
          />
          <BlockButton
            format="block-quote"
            icon={<FaQuoteLeft className="w-4 h-4" />}
          />
        </div>

        <div className="flex flex-row space-x-4">
          <BlockButton
            format="numbered-list"
            icon={<MdFormatListNumbered className="w-4 h-4" />}
          />
          <BlockButton
            format="bulleted-list"
            icon={<MdFormatListBulleted className="w-4 h-4" />}
          />
          <BlockButton
            format="left"
            icon={<CgFormatLeft className="w-4 h-4" />}
          />
          <BlockButton
            format="center"
            icon={<CgFormatCenter className="w-4 h-4" />}
          />
          <BlockButton
            format="right"
            icon={<CgFormatRight className="w-4 h-4" />}
          />
          <BlockButton
            format="justify"
            icon={<CgFormatJustify className="w-4 h-4" />}
          />
        </div>
      </div>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck
        autoFocus
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
        placeholder="Enter your prompt"
        className="cursor-input text-sm min-h-[128px] px-5 py-2 w-full rounded-md border-[#7D8398]/30 bg-white leading-9"
      />

      <div className="mt-4 flex flex-row flex-wrap bg-[#f2f2f2] items-center px-4 border-t py-1">
        <label className="text-black/60 font-medium">ADD: </label>
        {variablesList?.map((v) => (
          // TODO: Rename this to a better and easy to identify name like Pill or Button. These names are more generaized than the variable.
          <Variable
            data={v}
            onClick={(event) => {
              event.preventDefault();
              // Transforms.select(editor, target)
              insertMention(editor, v);
              // setTarget(null)
            }}
            className="bg-blue-100 text-[#0068C3] hover:bg-[#489CF0]/30 border-blue-400"
            key={v.id}
          />
        ))}

        {outputVariablesList?.map((v, index) => {
          return (
            <Variable
              data={v}
              onClick={(event) => {
                event.preventDefault();
                insertMention(editor, v, 'output');
              }}
              key={v.id}
              className="bg-purple-100 text-[#6b00c3] hover:bg-purple-200 border-purple-400"
            />
          );
        })}
      </div>
    </Slate>
  );
};

const toggleBlock = (editor, format) => {
  try {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    );
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    });
    let newProperties;
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      };
    } else {
      newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
      };
    }
    Transforms.setNodes(editor, newProperties);
    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  } catch (err) {
    // TODO: Add error handling
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = (props) => {
  const { attributes, children, element, outputVariablesList, variablesList } =
    props;

  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote
          {...attributes}
          className="pl-4 border-l-4 border-gray-400 italic"
        >
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul {...attributes} className="list-disc list-inside">
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes} className="text-2xl font-bold">
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes} className="text-xl font-semibold">
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li {...attributes} className="mb-1">
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol {...attributes} className="list-decimal list-inside">
          {children}
        </ol>
      );
    case 'mention':
      if (
        outputVariablesList.filter((v) => v.id === element.id).length === 0 &&
        variablesList.filter((v) => v.id === element.id).length === 0
      )
        return;
      return <Mention {...props} />;
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = (
      <code className="before:content-none after:content-none">{children}</code>
    );
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  return (
    <span
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      className={`${
        isActive ? 'text-black bg-gray-200' : 'text-gray-500'
      } hover:bg-gray-200 p-1`}
    >
      {icon}
    </span>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, format);

  return (
    <span
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      className={`${
        isActive ? 'text-black bg-gray-200' : 'text-gray-500'
      } hover:bg-gray-200 p-1`}
    >
      {icon}
    </span>
  );
};

const insertMention = (editor, variable, category = 'input') => {
  const mention = {
    type: 'mention',
    category: category,
    variable,
    id: variable.id,
    children: [{ text: '' }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
  // Transforms.collapse(editor, { edge: 'end' });
};

const Mention = ({
  attributes,
  children,
  element,
  variablesList,
  outputVariablesList,
}) => {
  const selected = useSelected();
  const focused = useFocused();

  const selectedVariable =
    variablesList.find(
      (v) => v.id === element.id || v.id === element.variable.id
    ) ||
    outputVariablesList.find(
      (v) => v.id === element.id || v.id === element.variable.id
    );

  const outputVariableIndex = outputVariablesList.findIndex(
    (outputVariable) => outputVariable.id === selectedVariable?.id
  );

  const style = {
    padding: '3px 3px 2px',
    margin: '0 1px',
    verticalAlign: 'baseline',
    display: 'inline-block',
    borderRadius: '4px',
    backgroundColor: '#eee',
    fontSize: '0.9em',
    boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
  };
  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0].bold) {
    style.fontWeight = 'bold';
  }
  if (element.children[0].italic) {
    style.fontStyle = 'italic';
  }
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${selectedVariable?.name}`}
      // style={style}
      className={`cursor-pointer w-fit text-sm font-medium inline-flex items-center px-2.5 py-1 rounded-3xl border  ${
        element.category === 'output'
          ? 'bg-purple-100 text-[#6b00c3] hover:bg-purple-200 border-purple-400'
          : 'border-blue-400 bg-blue-100 text-[#0068C3] hover:bg-[#489CF0]/30'
      }`}
    >
      {children} {selectedVariable?.label}
    </span>
  );
};

const initialValue = [
  {
    type: 'paragraph',
    children: [
      { text: 'This is editable ' },
      { text: 'rich', bold: true },
      { text: ' text, ' },
      { text: 'much', italic: true },
      { text: ' better than a ' },
      { text: '<textarea>', code: true },
      { text: '!' },
    ],
  },
  {
    type: 'paragraph',
    children: [
      {
        text: "Since it's rich text, you can do things like turn a selection of text ",
      },
      { text: 'bold', bold: true },
      {
        text: ', or add a semantically rendered block quote in the middle of the page, like this:',
      },
    ],
  },
  {
    type: 'block-quote',
    children: [{ text: 'A wise quote.' }],
  },
  {
    type: 'paragraph',
    align: 'center',
    children: [{ text: 'Try it out for yourself!' }],
  },
];

export default OutputEditor;

// const processNode = (node, variablesList, outputVariablesList) => {
//   if (Text.isText(node)) {
//     return node.text;
//   } else if (node.type === 'mention') {
//     const selectedVariable =
//       variablesList?.find((v) => v.id === node.variable.id) ||
//       outputVariablesList?.find((v) => v.id === node.variable.id);

//     return `\${${selectedVariable?.category}.${selectedVariable?.name}}`;
//   }

//   const processedPrompt = node.children
//     ?.map((n) => processNode(n, variablesList, outputVariablesList))
//     .join(' ');

//   return processedPrompt;
// };

// export const performPromptSerialization = (
//   nodes,
//   variablesList,
//   outputVariablesList
// ) => {
//   return nodes
//     ?.map((node) => processNode(node, variablesList, outputVariablesList))
//     .join(' ');
// };

// const OutputEditor = ({
//   variablesList = [],
//   outputVariablesList = [],
//   promptText,
//   setPromptText,
// }) => {
//   const ref = useRef();
//   const [target, setTarget] = useState();
//   const [index, setIndex] = useState(0);
//   const [search, setSearch] = useState('');
//   const renderElement = useCallback(
//     (props) => (
//       <Element
//         {...props}
//         variablesList={variablesList}
//         outputVariablesList={outputVariablesList}
//       />
//     ),
//     [variablesList, outputVariablesList]
//   );
//   const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
//   const editor = useMemo(
//     () => withMentions(withReact(withHistory(createEditor()))),
//     []
//   );

//   const getfilteredVariables = () => {
//     if (!search) return [...variablesList, ...outputVariablesList];

//     const filteredVariableList = variablesList.filter((variable) => {
//       return variable.label.toLowerCase().indexOf(search.toLowerCase()) >= 0;
//     });
//     const filteredOutputVariablesList = outputVariablesList.filter(
//       (variable) => {
//         return variable.label.toLowerCase().indexOf(search.toLowerCase()) >= 0;
//       }
//     );

//     return [...filteredVariableList, ...filteredOutputVariablesList];
//   };

//   const vars = getfilteredVariables() || [
//     ...variablesList,
//     ...outputVariablesList,
//   ];

//   const onKeyDown = useCallback(
//     (event) => {
//       if (target && vars.length > 0) {
//         switch (event.key) {
//           case 'ArrowDown': {
//             event.preventDefault();
//             const prevIndex = index >= vars.length - 1 ? 0 : index + 1;
//             setIndex(prevIndex);
//             break;
//           }
//           case 'ArrowUp': {
//             event.preventDefault();
//             const nextIndex = index <= 0 ? vars.length - 1 : index - 1;
//             setIndex(nextIndex);
//             break;
//           }
//           case 'Tab':
//           case 'Enter':
//             event.preventDefault();
//             Transforms.select(editor, target);
//             insertMention(editor, vars[index]);
//             setTarget(null);
//             break;
//           case 'Escape':
//             event.preventDefault();
//             setTarget(null);
//             break;
//         }
//       }
//     },
//     [index, search, target]
//   );

//   useEffect(() => {
//     if (target && vars.length > 0) {
//       const el = ref.current;
//       const domRange = ReactEditor.toDOMRange(editor, target);
//       const rect = domRange.getBoundingClientRect();
//       el.style.top = `${rect.top + window.pageYOffset + 24}px`;
//       el.style.left = `${rect.left + window.pageXOffset}px`;
//     }
//   }, [vars.length, editor, index, search, target]);

//   return (
//     <Slate
//       editor={editor}
//       value={JSON.parse(promptText || '[]')}
//       onChange={(value) => {
//         const { selection, operations, children } = editor;

//         if (selection && Range.isCollapsed(selection)) {
//           const [start] = Range.edges(selection);
//           const wordBefore = Editor.before(editor, start, { unit: 'word' });
//           const before = wordBefore && Editor.before(editor, wordBefore);
//           const beforeRange = before && Editor.range(editor, before, start);
//           const beforeText = beforeRange && Editor.string(editor, beforeRange);
//           const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
//           const after = Editor.after(editor, start);
//           const afterRange = Editor.range(editor, start, after);
//           const afterText = Editor.string(editor, afterRange);
//           const afterMatch = afterText.match(/^(\s|$)/);

//           if (beforeMatch && afterMatch) {
//             setTarget(beforeRange);
//             setSearch(beforeMatch[1]);
//             setIndex(0);
//             return;
//           }
//         }

//         setTarget(null);

//         // Handling the text change
//         const isAstChange = operations.some(
//           (op) => 'set_selection' !== op.type
//         );
//         if (isAstChange) {
//           const content = JSON.stringify(value);
//           setPromptText(content);
//         }
//       }}
//     >
//       <Editable
//         renderElement={renderElement}
//         renderLeaf={renderLeaf}
//         onKeyDown={onKeyDown}
//         placeholder="Enter your prompt"
//         className="cursor-pointer text-sm min-h-[128px] px-5 py-2 w-full rounded-md border-[#7D8398]/30 bg-white leading-9"
//       />

//       <div className="mt-4 flex flex-row flex-wrap bg-[#f2f2f2] items-center px-4 border-t py-1">
//         <label className="text-black/60 font-medium">ADD: </label>
//         {variablesList?.map((v) => (
//           // TODO: Rename this to a better and easy to identify name like Pill or Button. These names are more generaized than the variable.
//           <Variable
//             data={v}
//             onClick={(event) => {
//               event.preventDefault();
//               // Transforms.select(editor, target)
//               insertMention(editor, v);
//               // setTarget(null)
//             }}
//             className="bg-blue-100 text-[#0068C3] hover:bg-[#489CF0]/30 border-blue-400"
//             key={v.id}
//           />
//         ))}

//         {outputVariablesList?.map((v, index) => {
//           return (
//             <Variable
//               data={v}
//               onClick={(event) => {
//                 event.preventDefault();
//                 insertMention(editor, v, 'output');
//               }}
//               key={v.id}
//               className="bg-purple-100 text-[#6b00c3] hover:bg-purple-200 border-purple-400"
//             />
//           );
//         })}
//       </div>

//       {target && vars.length > 0 && (
//         <Portal>
//           <div
//             ref={ref}
//             style={{
//               top: '-9999px',
//               left: '-9999px',
//               position: 'absolute',
//               zIndex: 1,

//               background: 'white',
//               borderRadius: '4px',
//               boxShadow: '0 1px 5px rgba(0,0,0,.2)',
//             }}
//             className="cursor-pointer text-sm px-1 py-1"
//             data-cy="mentions-portal"
//           >
//             {vars?.map((variable, i) => (
//               <div
//                 key={variable.id}
//                 className="px-2 py-1 hover:bg-[#B4D5FF] bg-transparent rounded"
//                 onClick={(event) => {
//                   event.preventDefault();
//                   Transforms.select(editor, target);
//                   insertMention(editor, variable);
//                 }}
//               >
//                 {variable.label}
//               </div>
//             ))}
//           </div>
//         </Portal>
//       )}
//     </Slate>
//   );
// };

const withMentions = (editor) => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === 'mention' ? true : isVoid(element);
  };

  editor.markableVoid = (element) => {
    return element.type === 'mention' || markableVoid(element);
  };

  return editor;
};

// const insertMention = (editor, variable, category = 'input') => {
//   const mention = {
//     type: 'mention',
//     category: category,
//     variable,
//     id: variable.id,
//     children: [{ text: '' }],
//   };
//   Transforms.insertNodes(editor, mention);
//   Transforms.move(editor);
// };

// const Leaf = ({ attributes, children, leaf }) => {
//   if (leaf.bold) {
//     children = <strong>{children}</strong>;
//   }

//   if (leaf.code) {
//     children = <code>{children}</code>;
//   }

//   if (leaf.italic) {
//     children = <em>{children}</em>;
//   }

//   if (leaf.underline) {
//     children = <u>{children}</u>;
//   }

//   return <span {...attributes}>{children}</span>;
// };

// const Element = (props) => {
//   const { attributes, children, element, outputVariablesList, variablesList } =
//     props;

//   switch (element.type) {
//     case 'mention':
//       if (
//         outputVariablesList.filter((v) => v.id === element.id).length === 0 &&
//         variablesList.filter((v) => v.id === element.id).length === 0
//       )
//         return;
//       return <Mention {...props} />;
//     default:
//       return <p {...attributes}>{children}</p>;
//   }
// };

// export default OutputEditor;
