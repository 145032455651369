import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { TextInput } from './editorOld/RenderingComponents';
import { useRouter } from 'next/router';
import ImageContent from './editorOld/RenderingComponents/Image';
import { generateUserRequestObject } from '@/utils/schemaHelpers';
import axios from 'axios';
import OutputRenderer from './OutputRenderer';
import { toast } from 'react-toastify';

import { Button } from './editorOld/RenderingComponents/Button';
import { CheckboxGroup } from './editorOld/RenderingComponents/CheckboxGroup';
import { InputWithLabel } from './editorOld/RenderingComponents/InputWithLabel';
import { Paragraph } from './editorOld/RenderingComponents/Paragraph';
import { RadioButtonGroup } from './editorOld/RenderingComponents/RadioButtonGroup';
import { Text } from './editorOld/RenderingComponents/Text';
import { TextAreaWithLabel } from './editorOld/RenderingComponents/TextAreaWithLabel';

import { forgeDecrypt } from '@/utils/cipherHelpers';
import { nanoid } from 'nanoid';
import { getUserId } from '@/utils/userIdUtil';
import { EVENT_IDS } from '@/constants/enums/events';
import { useAppUser } from '@/contexts/AppUserContext';

const ProgressBar = ({ progress, color }) => {
  const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  const rgbaColor = color ? hexToRgb(color) : null;

  const backgroundColor = rgbaColor
    ? `rgba(${rgbaColor.r * 0.5}, ${rgbaColor.g * 0.5}, ${
        rgbaColor.b * 0.5
      }, 0.2)`
    : '#B4D7FB';
  const textColor = rgbaColor
    ? `rgba(${rgbaColor.r}, ${rgbaColor.g}, ${rgbaColor.b}, 0.85)`
    : color;

  return (
    <div className="flex flex-col items-center space-y-2 mb-10">
      <div className="flex flex-col items-center mt-10">
        <h1 style={{ color }} className="text-2xl font-medium leading-normal">
          Preparing Your Response...
        </h1>
        <p
          style={{ color: textColor }}
          className="pb-2 font-normal text-md leading-normal"
        >
          Please wait as your response is being generated.
        </p>
      </div>
      <div
        className="relative w-full md:w-3/4 h-2 bg-[#B4D7FB] rounded"
        style={{ backgroundColor }}
      >
        <div
          className="absolute top-0 left-0 h-2 bg-[#2483E2] rounded transition-all duration-300 ease-in-out"
          style={{ width: `${progress}%`, backgroundColor: color }}
        ></div>
      </div>
    </div>
  );
};

const DynamicForm = ({
  isSharingEnabled = true,
  isExecutable = true,
  ...props
}) => {
  const router = useRouter();

  const { auth0User: user } = useAppUser();
  const [output, setOutput] = useState(null);
  const [isOutputLoading, setIsOutputLoading] = useState(false);
  const [outputURL, setOutputURL] = useState('');
  const [formInitValues, setFormInitValues] = useState(props.initialValues);
  const [buttonStyles, setButtonStyles] = useState({});
  const [outputTextStyles, setOutputTextStyles] = useState(null);
  const [progress, setProgress] = useState(0);
  const [progressIncrement, setProgressIncrement] = useState(0.5);

  const { initialValues, inputs, validationSchema, appSchema, rawSchema } =
    props;

  useEffect(() => {
    const retrieveSavedOutput = async () => {
      try {
        setIsOutputLoading(true);
        const savedOutputResponse = await axios.post(
          '/api/supabase/fetchOutput',
          {
            unique_id: router?.query?.output,
            environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
          }
        );

        setFormInitValues(JSON.parse(savedOutputResponse?.data?.[0].inputs));
        try {
          setOutput(JSON.parse(savedOutputResponse?.data?.[0]?.output));
        } catch (e) {
          const outputObj = {
            node_outputs: [
              { output_data: savedOutputResponse?.data?.[0]?.output },
            ],
          };

          setOutput(outputObj);
        }

        setOutputURL(
          `${process.env.NEXT_PUBLIC_HOST_URL}/apps/${router.query.appId}?output=${router.query.output}`
        );
        setIsOutputLoading(false);
      } catch (err) {
        // Blank block for now
      } finally {
        setIsOutputLoading(false);
      }
    };
    if (router?.query?.output) {
      retrieveSavedOutput();
    }
  }, [router?.query?.output, router?.query?.appId]);

  const saveOutputToSupabase = async (inputs, output, execution_status) => {
    const saveOutputResponse = await axios.post('/api/supabase/saveOutput', {
      inputs: JSON.stringify(inputs),
      output: JSON.stringify(output),
      execution_status: execution_status,
      app_id: rawSchema.id,
      encrypted_app_id: router.query.appId,
      unique_id: nanoid(),
      environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    });

    return `${process.env.NEXT_PUBLIC_HOST_URL}/apps/${router.query.appId}?output=${saveOutputResponse.data[0].unique_id}`;
  };

  useEffect(() => {
    const timer = setInterval(updateProgress, 600); // Update every 600ms (1 min / 100 steps)

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  useEffect(() => {
    if (progress >= 50 && progress < 75) {
      setProgressIncrement(0.25);
    } else if (progress >= 75) {
      setProgressIncrement(0.1);
    }
  }, [progress]);

  const updateProgress = () => {
    if (progress < 100) {
      setProgress((prev) => prev + progressIncrement);
    }
  };

  // Old Schema since the top-level component has more than 1 child.
  // We should also check if the first child is a container. If so, that is new schema. Else old schema
  if (appSchema?.children?.length > 1)
    return (
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            if (!isExecutable) {
              toast.error('Please fix the errors before testing the app.', {
                className: 'toast-message-error',
              });
              return;
            }
            if (!rawSchema?.id || !rawSchema?.executable) {
              toast.error(
                'You have to publish the app before you can test it.',
                {
                  className: 'toast-message-error',
                }
              );
              return;
            }
            if (!isOutputLoading) {
              try {
                setIsOutputLoading(true);
                setOutputURL(null);

                const requestObject = generateUserRequestObject(
                  values,
                  rawSchema
                );

                const response = await axios.post(
                  `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/execute_schema`,
                  requestObject
                );

                setOutput(response.data.node_outputs[0].output_data);

                axios.post('/api/supabase/captureEvent', {
                  user_id: await getUserId(user?.email),
                  event_type: EVENT_IDS.GENERATE_OUTPUT_SUCCESS,
                  route: `/apps/${router.query?.appId}`,
                  app_id: forgeDecrypt(router.query?.appId),
                  metadata: {},
                });

                const url = await saveOutputToSupabase(
                  values,
                  response.data.node_outputs[0].output_data,
                  'success'
                );

                setOutputURL(url);
              } catch (err) {
                axios.post('/api/supabase/captureEvent', {
                  user_id: await getUserId(user?.email),
                  event_type: EVENT_IDS.GENERATE_OUTPUT_ERROR,
                  route: `/apps/${router.query?.appId}`,
                  app_id: forgeDecrypt(router.query?.appId),
                  metadata: {},
                });
                saveOutputToSupabase(
                  values,
                  'Error generating output.',
                  'error'
                );
                toast.error(
                  'We ran into an error when generating your response. Please try again. If issue persists, please contact us.',
                  {
                    className: 'toast-message-error',
                  }
                );
              } finally {
                setProgress(100);
                setTimeout(() => {
                  setIsOutputLoading(false);
                  setProgress(0);
                  setProgressIncrement(0.5);
                }, 1000);
              }
            } else {
              toast.error(
                'Please wait while your response is being processed.',
                {
                  className: 'toast-message-error',
                }
              );
            }
          }}
          enableReinitialize
        >
          {({ errors }) => (
            <Form className="mb-15">
              {inputs?.map(({ element, props }) => {
                switch (element) {
                  case 'Text':
                    return <Text {...props} />;

                  case 'Paragraph':
                    return <Paragraph {...props} />;

                  case 'Button':
                    setButtonStyles(props?.styles);
                    return <Button {...props} />;

                  case 'RadioButtonGroup':
                    if (!outputTextStyles?.color)
                      setOutputTextStyles({ color: props?.labelColor });
                    return <RadioButtonGroup {...props} />;

                  case 'CheckboxGroup':
                    if (!outputTextStyles?.color)
                      setOutputTextStyles({ color: props?.labelColor });
                    return <CheckboxGroup errors={errors} {...props} />;

                  // case 'select':
                  //   return (
                  //     <SelectInput
                  //       key={name}
                  //       label={props?.label}
                  //       name={name}
                  //       options={props?.options}
                  //       className={props?.styles || ''}
                  //     />
                  //   )

                  case 'TextAreaWithLabel':
                    if (!outputTextStyles?.color)
                      setOutputTextStyles({ color: props?.labelColor });
                    return (
                      <TextAreaWithLabel
                        label={props?.label}
                        key={props?.name}
                        name={props?.name}
                        className={props?.styles}
                        placeholder={props.placeholder}
                        description={props?.description}
                        required={props?.required}
                      />
                    );

                  case 'InputWithLabel':
                    if (!outputTextStyles?.color)
                      setOutputTextStyles({ color: props?.labelColor });
                    return (
                      <InputWithLabel
                        label={props?.label}
                        key={props?.name}
                        name={props?.name}
                        // className={props?.styles}
                        placeholder={props?.placeholder}
                        description={props?.description}
                        required={props?.required}
                      />
                    );

                  case 'ImageContent':
                    return (
                      <ImageContent
                        src={props?.src}
                        alt={props?.alt}
                        height={props?.height || 30}
                        width={props?.width || 30}
                        styles={props?.styles}
                      />
                    );

                  default:
                    return (
                      <TextInput
                        id={props?.name}
                        key={props?.name}
                        name={props?.name}
                        label={props?.label}
                        placeholder={props?.placeholder}
                        type={props?.type}
                        description={props?.description}
                      />
                    );
                }
              })}
            </Form>
          )}
        </Formik>

        <div className="grow ">
          {isOutputLoading && (
            <ProgressBar
              progress={progress}
              color={outputTextStyles?.color || '#000000'}
            />
          )}
          {!isOutputLoading && output && (
            <div>
              <OutputRenderer
                output={output}
                appId={rawSchema.id}
                url={outputURL}
                setURL={setOutputURL}
                outputTextStyles={outputTextStyles}
              />
            </div>
          )}
        </div>
      </>
    );
  // The else block renders the New Schema
  else
    return (
      <>
        <Formik
          initialValues={formInitValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            if (!isExecutable) {
              toast.error('Please fix the errors before testing the app.', {
                className: 'toast-message-error',
              });
              return;
            }
            if (!rawSchema?.id || !rawSchema?.executable) {
              toast.error(
                'You have to publish the app before you can test it.',
                {
                  className: 'toast-message-error',
                }
              );
              return;
            }
            if (!isOutputLoading) {
              try {
                axios.post('/api/supabase/captureEvent', {
                  user_id: await getUserId(user?.email),
                  event_type: EVENT_IDS.GENERATE_OUTPUT_CLICK,
                  route: `/apps/${router.query?.appId}`,
                  app_id: forgeDecrypt(
                    router?.query?.appId || rawSchema?.id || ''
                  ),
                  metadata: {},
                });
                setIsOutputLoading(true);
                setOutputURL(null);
                const requestObject = generateUserRequestObject(
                  values,
                  rawSchema
                );
                try {
                  const response = await axios.post(
                    `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/execute_schema`,
                    requestObject
                  );
                  setOutput(response.data);

                  axios.post('/api/supabase/captureEvent', {
                    user_id: await getUserId(user?.email),
                    event_type: EVENT_IDS.GENERATE_OUTPUT_SUCCESS,
                    route: `/apps/${router.query?.appId}`,
                    app_id: forgeDecrypt(
                      router?.query?.appId || rawSchema?.id || ''
                    ),
                    metadata: {},
                  });

                  const url = await saveOutputToSupabase(
                    values,
                    response.data,
                    'success'
                  );

                  setOutputURL(url);
                } catch (err) {
                  toast.error(
                    err?.response?.data?.errors ||
                      'We ran into an error when generating your response. Please try again. If issue persists, please contact us.',
                    {
                      className: 'toast-message-error',
                    }
                  );
                  axios.post('/api/supabase/captureEvent', {
                    user_id: await getUserId(user?.email),
                    event_type: EVENT_IDS.GENERATE_OUTPUT_ERROR,
                    route: `/apps/${router.query?.appId}`,
                    app_id: forgeDecrypt(router.query?.appId),
                    metadata: {},
                  });
                  saveOutputToSupabase(
                    values,
                    JSON.stringify('Error: ' + err),
                    'error'
                  );
                }
              } catch (err) {
                axios.post('/api/supabase/captureEvent', {
                  user_id: await getUserId(user?.email),
                  event_type: EVENT_IDS.GENERATE_OUTPUT_ERROR,
                  route: `/apps/${router.query?.appId}`,
                  app_id: forgeDecrypt(router.query?.appId),
                  metadata: {},
                });
                saveOutputToSupabase(
                  values,
                  JSON.stringify('Error: ' + err),
                  'error'
                );
                toast.error(
                  'We ran into an error when generating your response. Please try again. If issue persists, please contact us.',
                  {
                    className: 'toast-message-error',
                  }
                );
              } finally {
                setProgress(100);
                setTimeout(() => {
                  setIsOutputLoading(false);
                  setProgress(0);
                  setProgressIncrement(0.5);
                }, 1000);
              }
            } else {
              toast.error(
                'Please wait while your response is being processed.',
                {
                  className: 'toast-message-error',
                }
              );
            }
          }}
          enableReinitialize
        >
          {({ errors }) => (
            <Form className="w-full">
              {inputs?.map(({ element, props }) => {
                switch (element) {
                  case 'Text':
                    return <Text {...props} />;

                  case 'Paragraph':
                    return <Paragraph {...props} />;

                  case 'Button':
                    setButtonStyles(props?.styles);
                    return <Button {...props} />;

                  case 'RadioButtonGroup':
                    if (!outputTextStyles?.color)
                      setOutputTextStyles({ color: props?.labelColor });
                    return <RadioButtonGroup {...props} />;

                  case 'CheckboxGroup':
                    if (!outputTextStyles?.color)
                      setOutputTextStyles({ color: props?.labelColor });
                    return <CheckboxGroup errors={errors} {...props} />;

                  // case 'select':
                  //   return (
                  //     <SelectInput
                  //       key={name}
                  //       label={props?.label}
                  //       name={name}
                  //       options={props?.options}
                  //       className={props?.styles || ''}
                  //     />
                  //   )

                  case 'TextAreaWithLabel':
                    if (!outputTextStyles?.color)
                      setOutputTextStyles({ color: props?.labelColor });
                    return (
                      <TextAreaWithLabel
                        label={props?.label}
                        key={props?.name}
                        name={props?.name}
                        className={props?.styles}
                        placeholder={props.placeholder}
                        description={props?.description}
                        rows={props?.rows}
                        required={props?.required}
                        labelColor={props?.labelColor}
                        descriptionColor={props?.descriptionColor}
                      />
                    );

                  case 'InputWithLabel':
                    if (!outputTextStyles?.color)
                      setOutputTextStyles({ color: props?.labelColor });

                    return (
                      <InputWithLabel
                        label={props?.label}
                        key={props?.name}
                        name={props?.name}
                        // className={props?.styles}
                        placeholder={props?.placeholder}
                        description={props?.description}
                        required={props?.required}
                        labelColor={props?.labelColor}
                        descriptionColor={props?.descriptionColor}
                      />
                    );

                  case 'ImageContent':
                    return (
                      <ImageContent
                        src={props?.src}
                        alt={props?.alt}
                        height={props?.height || 30}
                        width={props?.width || 30}
                        styles={props?.styles}
                      />
                    );

                  default:
                    return (
                      <TextInput
                        id={props?.name}
                        key={props?.name}
                        name={props?.name}
                        label={props?.label}
                        placeholder={props?.placeholder}
                        type={props?.type}
                        description={props?.description}
                      />
                    );
                }
              })}
            </Form>
          )}
        </Formik>

        <div className="w-full">
          {isOutputLoading && (
            <ProgressBar
              progress={progress}
              color={outputTextStyles?.color || '#000000'}
            />
          )}
          {!isOutputLoading && output && (
            <div className="bg-transparent w-full my-10 rounded-md">
              <OutputRenderer
                output={output}
                url={outputURL}
                customizedOutputText={rawSchema.frontend_schema?.outputText}
                customizedOutput={rawSchema.frontend_schema.outputObject}
                buttonStyles={buttonStyles}
                outputTextStyles={outputTextStyles}
                isSharingEnabled={isSharingEnabled}
              />
            </div>
          )}
        </div>
      </>
    );
};

export default DynamicForm;
