import { generateValidations } from '../utils/getInputs';
import * as Yup from 'yup';

// export const getInputsFromJSONOld = (formSchema) => {
//   let initialValues = {}

//   let validationsFields = {}

//   for (const field of formSchema) {
//     initialValues[field.name] = field.value

//     if (!field.validations) continue

//     const schema = generateValidations(field)

//     validationsFields[field.name] = schema
//   }

//   return {
//     validationSchema: Yup.object({ ...validationsFields }),
//     initialValues,
//     inputs: formSchema,
//   }
// }

export const getInputsFromJSON = (formSchema) => {
  let initialValues = {};

  let validationsFields = {};

  for (const field of formSchema) {
    if (field.element === 'CheckboxGroup') {
      initialValues[field.props.name] = [];
      if (field.props.required) {
        validationsFields[field.props.name] = Yup.array().min(
          1,
          'At least one checkbox must be checked'
        );
      }
    } else if (
      field.element === 'InputWithLabel' ||
      field.element === 'TextAreaWithLabel' ||
      field.element === 'RadioButtonGroup'
    ) {
      initialValues[field.props.name] = '';
    }

    // TODO: P0 --> Bring back validations again!

    // if (!field.validations) continue

    // const schema = generateValidations(field)

    // validationsFields[field.name] = schema
  }

  return {
    validationSchema: Yup.object({ ...validationsFields }),
    initialValues,
    inputs: formSchema,
  };
};
