import FingerprintJS from '@fingerprintjs/fingerprintjs';
import crypto from 'crypto';
import { forgeEncrypt } from './cipherHelpers';
import axios from 'axios';

const USER_ID_COOKIE_NAME = 'ha_unique_user_id';
const USER_ID_LOCAL_STORAGE_KEY = 'ha_unique_user_id';

function setCookie(name, value, days) {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; path=/`;
}

function getCookie(name) {
  const cookies = document.cookie.split('; ').reduce((cookies, item) => {
    const [key, value] = item.split('=');
    cookies[key] = value;
    return cookies;
  }, {});
  return cookies[name];
}

async function getFingerprint() {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  return result.visitorId;
}

function getCanvasFingerprint() {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = '18pt Arial';
  context.textBaseline = 'top';
  context.fillText('FingerprintTest', 2, 2);
  return canvas.toDataURL();
}

function getWebGLFingerprint() {
  const canvas = document.createElement('canvas');
  const gl =
    canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
  if (!gl) {
    return null;
  }
  const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
  return debugInfo ? gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) : null;
}

function getAudioContextFingerprint() {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const oscillator = audioContext.createOscillator();
  const analyser = audioContext.createAnalyser();
  const destination = audioContext.createMediaStreamDestination();
  oscillator.connect(analyser);
  analyser.connect(destination);
  oscillator.start(0);
  const fingerprint = analyser.frequencyBinCount;
  oscillator.disconnect();
  return fingerprint;
}

async function getBatteryStatus() {
  if ('getBattery' in navigator) {
    const battery = await navigator.getBattery();
    return {
      level: battery.level,
      charging: battery.charging,
      chargingTime: battery.chargingTime,
      dischargingTime: battery.dischargingTime,
    };
  }
  return null;
}

const customFingerPrint = async () => {
  try {
    const userAgent = navigator.userAgent;
    const language = navigator.language;
    const platform = navigator.platform;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const screenResolution = `${window.screen.width}x${window.screen.height}`;
    const colorDepth = window.screen.colorDepth;

    const plugins = Array.from(navigator.plugins)
      ?.map((plugin) => plugin.name)
      .join(',');

    const canvasFingerprint = getCanvasFingerprint();
    const webGLFingerprint = getWebGLFingerprint();

    const hardwareConcurrency = navigator.hardwareConcurrency;
    const deviceMemory = navigator.deviceMemory;

    const touchSupport = 'ontouchstart' in window;
    const maxTouchPoints = navigator.maxTouchPoints;
    const audioContextFingerprint = getAudioContextFingerprint();

    const batteryStatus = await getBatteryStatus();

    const connection =
      navigator?.connection ||
      navigator?.mozConnection ||
      navigator?.webkitConnection;
    const connectionType = connection ? connection.effectiveType : null;
    const downlink = connection ? connection.downlink : null;

    const indexedDBSupport = 'indexedDB' in window;
    const localStorageSupport = 'localStorage' in window;
    const sessionStorageSupport = 'sessionStorage' in window;
    const geolocationSupport = 'geolocation' in navigator;
    const webRTCSupport =
      'RTCPeerConnection' in window ||
      'mozRTCPeerConnection' in window ||
      'webkitRTCPeerConnection' in window;

    const mediaDevicesSupport = 'mediaDevices' in navigator;
    const networkInformation =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;

    const fingerprint = crypto
      .createHash('sha256')
      .update(
        `${userAgent}${language}${platform}${timezone}${screenResolution}${colorDepth}${plugins}${canvasFingerprint}${webGLFingerprint}${hardwareConcurrency}${deviceMemory}${touchSupport}${maxTouchPoints}${audioContextFingerprint}${JSON.stringify(
          batteryStatus
        )}${connectionType}${downlink}${indexedDBSupport}${localStorageSupport}${sessionStorageSupport}${geolocationSupport}${webRTCSupport}${mediaDevicesSupport}${JSON.stringify(
          networkInformation
        )}`
      )
      .digest('hex');

    return fingerprint;
  } catch (err) {
    return null;
  }
};

function getUserMapFlagKey(email, fingerprint) {
  const hashedEmail = crypto.createHash('sha256').update(email).digest('hex');
  return `userMapUpdated_${hashedEmail}_${fingerprint}`;
}

// export async function getUserId(userEmail = null) {
//   let userId = getCookie(USER_ID_COOKIE_NAME);

//   if (!userId) {
//     userId = localStorage.getItem(USER_ID_LOCAL_STORAGE_KEY);
//   }

//   if (!userId) {
//     userId = (await customFingerPrint()) || (await getFingerprint());
//     setCookie(USER_ID_COOKIE_NAME, userId, 365);
//     localStorage.setItem(USER_ID_LOCAL_STORAGE_KEY, userId);
//   }

//   if (userEmail) {
//     const userMapUpdated = localStorage.getItem(
//       getUserMapFlagKey(userEmail, userId)
//     );

//     if (!userMapUpdated) {
//       axios.post('/api/supabase/upsertUserMapping', {
//         fingerprint: userId,
//         email: userEmail,
//       });
//       localStorage.setItem(getUserMapFlagKey(userEmail, userId), 'true');
//     }
//   }

//   return userId;
// }

export async function getUserId(userEmail = null) {
  let userId = getCookie(USER_ID_COOKIE_NAME);
  let storageAvailable = true;

  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
  } catch (e) {
    storageAvailable = false;
  }

  if (!userId && storageAvailable) {
    userId = localStorage.getItem(USER_ID_LOCAL_STORAGE_KEY);
  }

  if (!userId) {
    userId = (await customFingerPrint()) || (await getFingerprint());
    setCookie(USER_ID_COOKIE_NAME, userId, 365);

    if (storageAvailable) {
      localStorage.setItem(USER_ID_LOCAL_STORAGE_KEY, userId);
    }
  }

  if (userEmail) {
    const userMapFlagKey = getUserMapFlagKey(userEmail, userId);
    let userMapUpdated;

    if (storageAvailable) {
      userMapUpdated = localStorage.getItem(userMapFlagKey);
    } else {
      userMapUpdated = getCookie(userMapFlagKey);
    }

    if (!userMapUpdated) {
      axios.post('/api/supabase/upsertUserMapping', {
        fingerprint: userId,
        email: userEmail,
      });

      if (storageAvailable) {
        localStorage.setItem(userMapFlagKey, 'true');
      } else {
        setCookie(userMapFlagKey, 'true', 365);
      }
    }
  }

  return userId;
}
