import Image from 'next/image';
import Link from 'next/link';

export default function AppFooter() {
  return (
    <Link href="/" className="font-normal">
      <div ClassName="w-20">
        <div className="bg-white px-4 py-2.5 rounded-lg font-semibold">
          <div className="flex items-center">
            <Image
              priority
              src="/assets/icons/Icon.svg"
              height={0}
              width={20}
              className="mt-0.5"
              alt="Rubber Logo"
            />
            <label className="pl-2 text-black mt-1 text-sm cursor-pointer">
              Powered by Rubber
            </label>
          </div>
        </div>

        {/*<footer className="w-full justify-between flex fixed left-0 bottom-0 bg-white md:px-10 px-6 rounded-lg shadow py-4 h-14 text-black">
      <Link href="/" className="font-normal">
        <div className="flex-row flex justify-center">
          <div className="flex">
            <Image
              priority
              src="/assets/icons/Icon.svg"
              height={0}
              width={20}
              alt="Rubber Logo"
            />
            <label className="pl-2 mt-1 text-sm cursor-pointer">
              Powered by Rubber
            </label>
          </div>
        </div>
      </Link>
      <Link href="/" className="font-normal">
        <div className="flex-row sm:flex  hidden sm:visible">
          <label className="pl-2 mt-1 text-sm cursor-pointer">
            Build AI-powered apps in minutes{' '}
            <span aria-hidden="true">&rarr;</span>
          </label>
        </div>
      </Link>*/}
      </div>
    </Link>
  );
}
