import Image from 'next/image';
import React from 'react';

const ImageContent = ({ src, alt, height, width, styles }) => {
  return (
    <div className="justify-start items-start flex flex-col w-full my-4 px-2 py-2">
      <Image
        src={src}
        alt={alt || 'Image'}
        width={width}
        height={height}
        style={styles}
      />
    </div>
  );
};

export default ImageContent;
